<template>
    <v-card>
        <v-card-title>
            <div class="d-flex align-center" style="gap: 16px;">
                <NavButton />
                <h2 class="text-h6 text-uppercase font-weight-bold">Reporte contrataciones pendientes</h2>
            </div>
        </v-card-title>
        <v-divider />
        <v-card-text>
            <v-tabs
            v-model="tab"
                background-color="transparent"
                color="basil"
                grow
            >
                <v-tab key="cierre_contratos" tab-value="cierre_contratos">
                    Cierre de contratos
                </v-tab>
                <v-tab key="planificacion_vs_contratacion" tab-value="planificacion_vs_contratacion">
                    Planificación vs contratación
                </v-tab>
                <v-tabs-items v-model="tab">
                    <v-tab-item key="cierre_contratos" value="cierre_contratos">
                        <PanelCierreContratosInstitucion />
                    </v-tab-item>
                    <v-tab-item key="planificacion_vs_contratacion" value="planificacion_vs_contratacion">
                        <PanelPlanificacionVsContratacion />
                    </v-tab-item>
                </v-tabs-items>
            </v-tabs>
        </v-card-text>
    </v-card>
</template>
<script>
import PanelPlanificacionVsContratacion from './planificacionVsContratacion/PanelPlanificacionVsContratacion.vue';
import PanelCierreContratosInstitucion from './cierreContratosInstitucion/PanelCierreContratosInstitucion.vue';
import NavButton from '@/components/utils/NavButton.vue';

export default {
    components: { NavButton, PanelCierreContratosInstitucion, PanelPlanificacionVsContratacion },
    data: () => ({
        tab: null,
    }),
}
</script>